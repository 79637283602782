<template>
  <div class="row">
    <div class="animated fadeIn" >
      <transition name="fade" mode="out-in" :duration="{ enter: 300, leave: 800 }">
        <div class="card" v-if="show_list">
          <div class="card-body">
            <h4 class="box-title">Actividades
              <router-link to="/courses" class="pull-right btn btn-danger"
              title="Click para regresar">
                <i class="fa fa-arrow-left fa-lg"></i>
              </router-link>
            </h4>
            <div class="row">
              <div class="col-12 col-md-4">
                <label for="" style="font-size: 12px;">Cursos</label>
                <select :disabled="sending_request"
                style="font-size: 12px;"
                v-model="filters.course" class="form-control">
                  <option value="">Elegir un curso</option>
                  <option :value="item.course"
                  :key="index" v-for="(item, index) in list_courses" >
                  {{item.course_name}}
                  </option>
                </select>
                <a href="#" style="font-size: 12px;" class="text-danger"
                @click.prevent="reset_filters()"
                title="Click para limpiar filtros">Click para ver todas las guías</a>
                <p style="font-size: 12px;" class="text-disabled">
                Por defecto se listas las actividades de la unidad en desarrollo.</p>
              </div>
              <div class="col-12 col-md-2">
                <label style="font-size: 11px;" for="">Unidad</label>
                <select :disabled="sending_request"
                style="font-size: 12px;"
                v-model="filters.bimestre" class="form-control">
                  <option value="">Elegir una unidad</option>
                  <option :value="item.id"
                  :key="index" v-for="(item, index) in list_bimester" >
                  {{item.name}}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-2 col-sm-4">
                <label for="" style="font-size: 12px;">Fecha de entrega</label>
                <input :disabled="sending_request" style="font-size: 12px;"
                type="date" name="feche_entrega"
                v-model="filters.date_activity" class="form-control">
              </div>
              <div class="col-10 col-md-3">
                <br>
                <div class="checkbox">
                  <label for="entregado" >
                    <input :disabled="sending_request" type="checkbox" v-model="filters.done"
                    id="entregado" class="form-check-input">
                    <span v-if="!filters.done" style="font-size:12px;">
                    Solo guías no entregadas</span>
                    <span v-if="filters.done" style="font-size:12px;">
                    Solo guías entregadas</span>
                  </label>
                </div>
                <p style="font-size:10px;"
                class="legendLabel">
                Click para filtrar guías entregas o guías no entregadas</p>
              </div>
              <div class="col-1">
                <br>
                <a href="" @click.prevent="list()"
                class="btn btn-outline-primary btn-lg">
                <i v-if="sending_request" title="Enviando..." class="fa fa-refresh"></i>
                <i v-if="!sending_request" title="Consultar" class="fa fa-send"></i>
              </a>
              </div>
            </div>
          </div>
          <div class="card-body--">
            <div class="table-stats order-table ov-h">
              <table class="table ">
                <thead>
                  <tr>
                    <th class="serial">#</th>
                    <th >Curso</th>
                    <th>Nombre</th>
                    <th class="serial">Fecha entrega </th>
                    <th class="serial" title="El valor de la actividad">Ponderación</th>
                    <th title="Nota obtenida">Mi nota</th>
                    <th >Entregada?</th>
                  </tr>
                </thead>
                <tbody>
                   <tr class="on-over"
                   v-for="(item, index) in list_data"
                   :key="index" @click="detalle_tarea(item, index)">
                    <td class="serial">{{index + 1}}</td>
                    <td>{{item.task_obj.course_name}}</td>
                    <td>  <span class="name">{{item.task_obj.name}}</span> </td>
                    <td class="serial">
                      <span
                      class="product">
                    {{item.task_obj.date_activity|to_date}}</span> </td>
                    <td class="serial"><span class="count">{{item.task_obj.note}}</span></td>
                    <td >
                      <span v-if="item.note">{{item.note}}</span>
                      <span v-else>0</span>
                    </td>
                    <td >
                      <span v-if="item.done" class="badge badge-complete">
                        {{item.done|status_to_text}}</span>
                      <span v-else class="badge badge-pending">
                        {{item.done|status_to_text}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> <!-- /.table-stats -->
          </div>
        </div> <!-- /.card -->
      </transition>
    </div>
    <div class="animated fadeIn" >
      <transition name="fade" mode="out-in" :duration="{ enter: 300, leave: 800 }">
        <div class="card" v-if="!show_list">
          <div class="card-body">
            <h4 class="box-title">
              Detalle actividad
              <a href="#" title="Regresar" @click.prevent="hidden_detalle"
              class="pull-right btn btn-danger">
                <i class="fa fa-arrow-left fa-lg"></i>
              </a>
            </h4>
            <br>
            <div class="row">
              <div class="col-12 col-lg-7">
                <div class="card">
                  <div class="card-body">
                    <div class="card-content">
                      <p class="text-danger alert alert-danger" role="alert" v-if="diff_days(
                      current_task.task_obj.date_activity) < 0">
                        Faltan <span style="font-size: 23px;">
                        {{diff_days(current_task.task_obj.date_activity)}}</span> dias
                      para la entrega</p>
                      <p v-else class="alert alert-success"  role="alert">
                        Faltan {{diff_days(current_task.task_obj.date_activity)}} dias
                      para la entrega</p>
                      <h5>{{current_task.task_obj.name}}</h5>
                      <p>{{current_task.task_obj.description}}</p>
                      <div class="alert alert-danger" role="alert"
                      v-if="current_task.entrega_fuera_fecha">
                        Has sido marcado para entregar la tarea fuera de fecha, tienes
                        {{diff_days_timestamp(current_task.fecha_fuera_fecha)}} dias
                        para la entrega
                      </div>
                      <div class="row">
                        <div class="col-md-3 col-sm-3" style="max-width: 300px;">
                          <a href="#">
                            <i class="fa fa-paperclip"></i>
                            Guía:
                            <span v-if="current_task.task_obj.guia_path"
                              class="badge badge-warning  r-activity">
                              <a style="font-size: 11px;"
                              :href="base_url+current_task.task_obj.guia_path"
                              target="_blank">
                              Click para ver la guía:
                              {{current_task.task_obj.guia_name.slice(7)}}
                              </a></span>
                            <span v-else class="badge badge-warning  r-activity">
                              -----</span>
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col" style="font-size: 12px;">
                          <h3>Recursos</h3>
                          <span v-if="current_task.task_obj">
                            <span style="color: #878787;"
                            v-if="current_task.task_obj.files.length == 0">
                              No se ha adjuntado recursos
                            </span>
                          </span>
                          <span v-if="!current_task.task_obj.files" style="color: #878787;">
                            No se ha adjuntado recursos
                          </span>
                          <ul class="list-group">
                            <li class="list-group-item
                            d-flexjustify-content-betweenalign-items-center"
                            v-for="(item, index) in current_task.task_obj.files_obj"
                            :key="index">
                              <a target="_blank" :href="base_url+item.path">{{item.name}}</a>
                            </li>
                          </ul>
                        </div>
                        <br>
                        <br>
                        <br>
                        <br>
                      </div>
                      <div class="row">
                        <div class="col" style="font-size: 12px;">
                          <hr>
                          <h3>Link de recursos</h3>
                          <span v-if="current_task.task_obj">
                            <span style="color: #878787;"
                            v-if="current_task.task_obj.links.length == 0">
                              No se ha adjuntado links de recursos
                            </span>
                          </span>
                          <span v-if="!current_task.task_obj.links" style="color: #878787;">
                            No se agregaron links de recursos
                          </span>
                          <ul class="list-group">
                            <li class="list-group-item
                            d-flexjustify-content-betweenalign-items-center"
                            v-for="(item, index) in current_task.task_obj.links_obj"
                            :key="index">
                              <a target="_blank" :href="item.link">{{item.name}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <hr>
                  <div class="card-body">
                    <div class="card-title">
                      <h5>Mi tarea</h5>
                    </div>
                    <div class="card-content">
                      <div class="row">
                        <div
                          class="col"
                          v-if="
                            diff_days(
                            current_task.task_obj.date_activity) >=0
                            && !current_task.entrega_fuera_fecha">
                          <upload
                            :avatar_id="current_task.document"
                            v-on:get_id_avatar="set_document"
                            :image_previo="false"></upload>
                        </div>
                        <div
                          class="col"
                          v-if="
                          diff_days(
                          current_task.task_obj.date_activity) >=0
                          && !current_task.entrega_fuera_fecha">
                          <button
                          @click.prevent="delivery"
                          class="btn btn-primary">Entregar</button>
                          <br>
                        </div>
                        <!--SI EL ESTUDIANTE ESTA MARCADO PARA ENTREGAR FUERA DE FECHA -->
                        <div
                          class="col"
                          v-if="current_task.entrega_fuera_fecha">
                          <upload
                            :avatar_id="current_task.document"
                            v-on:get_id_avatar="set_document"
                            :image_previo="false"></upload>
                        </div>
                        <div
                          class="col"
                          v-if="current_task.entrega_fuera_fecha">
                          <button
                          @click.prevent="delivery"
                          class="btn btn-primary">Entregar</button>
                          <br>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">
                      <h5>Mis tareas</h5>
                      <p>Aquí puedes subir más archivos</p>
                    </div>
                    <div class="card-content">
                      <div class="row">
                        <div class="col-7"
                        v-if=" diff_days(current_task.task_obj.date_activity) >=0
                        && !current_task.entrega_fuera_fecha">
                          <span v-if="current_task.document_name" style="font-size: 12px;">
                            <i class="fa fa-paperclip"></i>
                          <a :href="base_url+current_task.document_path" target="_blank">
                          Click para ver documento: {{current_task.document_name}}
                          </a></span>
                          <hr>
                          <uploadMulti
                            v-on:get_id_document="set_files"></uploadMulti>
                        </div>
                        <div class="col-7"
                        v-if="current_task.entrega_fuera_fecha">
                          <span v-if="current_task.document_name" style="font-size: 12px;">
                            <i class="fa fa-paperclip"></i>
                          <a :href="base_url+current_task.document_path" target="_blank">
                          Click para ver documento: {{current_task.document_name}}
                          </a></span>
                          <hr>
                          <uploadMulti
                            v-on:get_id_document="set_files"></uploadMulti>
                        </div>
                        <div class="col-7">
                          <h5>Mis archivos</h5>
                          <ul class="list-group">
                            <li class="list-group-item
                            d-flexjustify-content-betweenalign-items-center"
                            v-for="(item, index) in current_task.files_obj" :key="index"
                            style="font-size: 14px;" :class ="{ 'null': item.active }">
                              <a v-if="!item.active"
                              target="_blank" :href="base_url+item.path">
                              {{item.name}}</a>
                              <a v-if="item.active">{{item.name}}</a>
                              <p v-if="!current_task.done && !item.active">
                                <a style="color: #f03d3da6 !important;"
                                href="#"
                                @click.prevent="delete_image(item, index)">Eliminar</a>
                              </p>
                            </li>
                            <li v-if="current_task.files.length == 0" class="list-group-item
                            d-flexjustify-content-betweenalign-items-center">
                              <p style="color: grey;">No se han cargado archivos.</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-7 col-sm-8">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title box-title">Mensajes</h4>
                      <div class="card-content">
                        <div class="messenger-box">
                          <h5 v-if="current_task.message_obj.length === 0"
                          class="text-danger" >Sin mensajes</h5>
                          <ul style="height: 350px; overflow: auto;">
                            <br>
                            <li v-for="(message, index) in current_task.message_obj"
                            :key="index" >
                            <br>
                                <div class="msg-received msg-container"
                                v-if="user != message.user && !message.is_trashed">
                                  <div class="msg-box">
                                    <div class="inner-box"
                                    style="background-color: #00c292!important;
                                    color:#f4f4f4;">
                                      <div class="name">
                                        {{message.user_name}}
                                      </div>
                                      <div class="meg">
                                        {{message.comment}}
                                        <p style="font-size: 10px; color: #e8e9ef;">
                                          {{message.created_at|to_date}}
                                          {{message.created_at|to_time}}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div><!-- /.msg-received -->
                                <div class="msg-sent msg-container"
                                v-if="user === message.user">
                                  <div class="avatar">
                                    <img v-if="avatar_url"
                                    :src="'data:image/png;base64,' + avatar_url"
                                    class="user-avatar rounded-circle" alt="User Avatar">
                                  </div>
                                  <div class="msg-box">
                                    <div class="inner-box ">
                                      <div class="name">
                                        {{message.user_name}}
                                      </div>
                                      <div class="meg">
                                        {{message.comment}}
                                        <p style="font-size: 10px; color: #818a8b;">
                                          {{message.created_at|to_date}}
                                          {{message.created_at|to_time}}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div><!-- /.msg-sent -->
                              </li>
                          </ul>
                          <div class="send-mgs">
                            <form @submit.prevent="send_message">
                              <div class="yourmsg">
                                <input class="form-control"
                                placeholder="Escriba un mensaje."
                                v-model="message" type="text">
                              </div>
                              <button class="btn btn-primary mb-2" v-if="sending_request"
                              title="Enviando mensaje">
                                <i class="fa fa-refresh"></i>
                              </button>
                              <button class="btn msg-send-btn"
                              type="submit" v-if="!sending_request">
                                <i class="fa fa-send"></i>
                              </button>
                            </form>

                          </div>
                        </div><!-- /.messenger-box -->
                      </div>
                    </div> <!-- /.card-body -->
                  </div><!-- /.card -->
                </div>
            </div>
            <div class="col-12 col-lg-5">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-envelope-o"></i>
                    Grado y sección:
                    <span class="badge badge-warning pull-right">
                    {{current_task.task_obj.grade_name}}
                    {{current_task.task_obj.section_name}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-envelope-o"></i>
                    Bimestre:
                    <span class="badge badge-warning pull-right">
                      {{current_task.task_obj.bimestre_name}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-tasks"></i>
                    Curso:
                    <span class="badge badge-warning pull-right">
                      {{current_task.task_obj.course_name}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-calendar"></i>
                    Fecha entrega:
                    <span
                    class="badge badge-warning pull-right">
                    {{current_task.task_obj.date_activity|to_date}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-get-pocket"></i>
                    Tipo actividad:
                    <span class="badge badge-warning pull-right r-activity">
                      {{current_task.task_obj.type_activity_name}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-check"></i>
                    Mi nota:
                    <span v-if="current_task.note"
                      class="badge badge-warning pull-right r-activity">
                      {{current_task.note}}</span>
                    <span v-else
                      class="badge badge-warning pull-right r-activity">
                      ----</span>
                    </a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-paperclip"></i>
                    Documento
                    <span v-if="current_task.document_name"
                      class="badge badge-warning pull-right r-activity">
                      <a :href="base_url+current_task.document_path" target="_blank">
                      {{current_task.document_name}}
                      </a></span>
                    <span v-else class="badge badge-warning pull-right r-activity">
                      -----</span>
                    </a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-calendar"></i>
                    Fecha entregada:
                    <span class="badge badge-warning pull-right r-activity">
                      {{current_task.delivery_date|to_date_time}}</span></a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-pencil"></i>
                    Entregada:
                    <span v-if="current_task.done"
                      class="badge badge-success pull-right r-activity">
                      {{current_task.done|status_to_text}}</span>
                    <span  v-else class="badge badge-pending pull-right r-activity">
                      {{current_task.done|status_to_text}}</span>
                    </a>
                </li>
                <li class="list-group-item">
                  <a href="#">
                    <i class="fa fa-null"></i>
                    Entregada fuera de fecha:
                    <div
                    v-if="current_task.delivery_date_secund ||
                    current_task.delivery_date_first">
                      <span v-if="current_task.delivery_date_first"
                      title="Fecha primera entrega"
                      class="badge badge-warning">
                      {{current_task.delivery_date_first|to_date_time}}</span>
                      <span title="Fecha segunda entrega"
                      class="badge badge-warning">
                      {{current_task.delivery_date_secund|to_date_time}}</span>
                      </div>
                    <span  v-else class="badge badge-success pull-right r-activity">
                      NO</span>
                    </a>
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div> <!-- /.card -->
      </transition>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

/* eslint-disable */
export default {
  name: 'Tasks',
  components: {
    upload: () => import('../components/Upload.vue'),
    uploadMulti: () => import('../components/UploadMulti.vue'),
  },
  created(){
    this.course_id =  this.$route.params.course_id
    this.unidad_id =  this.$route.params.unidad_id
    if (this.$store.state.user) {
      this.user = this.$store.state.user.user_id
      this.avatar_url = this.$store.state.settings_obj.avatar_url
    }
    this.get_list_courses();
    this.get_list_bimestres();  
    setTimeout(()=>{
      let current_filters = this.get_data_storage('filters_task')      
      if(!current_filters){
        this.reset_filters();
      }
      if(current_filters){
        this.filters = current_filters
      }
      this.list();
    }, 100)  
  },
  data() {
    return {
      user: null,
      list_data: [],
      url_file: 'pictures/',
      url: 'tasks/',
      url_courses: 'courses/',
      url_bimestre: 'bimestres/',
      show_list: true,
      current_task: [],
      course_id: null,
      message: '',
      sending_request: false,
      list_bimester: [],
      list_courses: [],
      filters: {
        bimestre: '',
        course: ''
      },
      base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {    
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {      
      return JSON.parse(localStorage.getItem(name));      
    },
    reset_filters: function () {            
      this.filters = {
        bimestre: '',
        course: ''
      }
      this.remove_storage('filters_task')
      this.list()
    },
    diff_days: function(fecha) {
      let now = moment();      
      return moment(fecha).diff(now.format('YYYY-MM-DD'), 'days')      
    },
    diff_days_timestamp: function(fecha) {
      let now = moment();      
      let date = moment(fecha).format('YYYY-MM-DD')

      return moment(date).diff(now.format('YYYY-MM-DD'), 'days')      
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
    send_message() {
      let payload_m= {}
      payload_m.id = this.current_task.id
      payload_m.create_message = "True"
      payload_m.from_student_to_task = "True"
      payload_m.comment = this.message
      if (this.sending_request) return false
      this.sending_request = true
      self = this
      self.retrieveRequest(payload_m).then((data) => {
            self.$swal.fire('Envido', 'El mensjae ha sido enviado.', 'success');
            this.$set(self.list_data,
              self.current_task.index, data.data)
            self.current_task = data.data
            self.message = ''
            self.sending_request = false
          }).catch( (data) =>{
            self.sending_request = false
            this.handlerError(data)
      });
    },
    delivery () {
      let payload = {}
      payload.delivery = 'True'
      payload.id = this.current_task.id      
      const self = this;
      this.retrieveRequest(payload).then((data) => {
        this.$set(self.list_data,
          self.current_task.index, data.data)
        self.current_task = data.data
        self.$swal.fire('Entrega exitosa', 'La tarea ha sido entregada.', 'success');
      }).catch((error) =>{
        this.handlerError(error)
      });
    },
    hidden_detalle() {
      this.show_list = true
    },
    set_files(file_obj) {
      const self = this;
      let array_items =  []
      let temp_item = { }
      
      if (self.current_task.files_obj) {
        array_items = self.current_task.files_obj
      }
      temp_item = {
        'id': file_obj.id,
        'name': file_obj.name,
        'path': file_obj.path,
      }
      array_items.unshift(temp_item)
      self.current_task.files.push(file_obj.id)
      self.$set(self.current_task, 'files_obj', array_items)
      if (file_obj.finalizado) {
        this.update_task()
      }
    },
    set_document(document_id) {      
      let payload = {}
      payload.change_document = 'True'
      payload.id = this.current_task.id
      payload.document = document_id
      const self = this;
      this.retrieveRequest(payload).then((data) => {
        this.$set(self.list_data,
          self.current_task.index, data.data)
        self.current_task = data.data
        self.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
      }).catch((error) =>{
        this.handlerError(error)
      });
    },
    detalle_tarea(item, index) {
      this.current_task = item
      this.current_task.index = index
      this.show_list = false
    },
    list() {
      let payload = {};      
      payload.ciclo = this.$store.state.settings_obj.ciclo
      if (this.course_id && this.unidad_id != undefined) {
        this.filters.course = this.course_id
        this.course_id = null
      }
      if (this.unidad_id && this.unidad_id != undefined) {
        this.filters.bimestre = this.unidad_id
        this.unidad_id = null
      }
      
      if (this.filters.course){
        payload.course = this.filters.course
      }
      if (this.filters.bimestre){
        payload.bimestre = this.filters.bimestre
      }
      if (this.filters.date_activity){
        payload.date_activity = this.filters.date_activity
      }
      if (this.filters.done && this.filters.done != null) {
        payload.done = 'True'
      }
      if (!this.filters.done && this.filters.done != null) {
        payload.done = 'False'
      }
      if (this.sending_request) return false
      this.sending_request = true
      const self = this;
      this.sendRequest(payload).then((data) => {
        this.save_storage('filters_task', this.filters,)
        self.list_data = data.data;            
        this.sending_request = false
      }).catch((error) => {
        this.handlerError(error)
        this.sending_request = false
      });
      return null;
    },
    get_list_courses() {
      const payload = {};
      payload.basic_serializer = 'True'
      payload.ciclo = this.$store.state.settings_obj.ciclo
      if (this.course_id) {
        payload.student = this.$store.state.settings_obj.student
      }
      const self = this;
      this.sendRequest_with_url(this.url_courses, payload).then((data) => {      
        self.list_courses = data.data;
      }).catch((error) => {
        this.handlerError(error)
      });
      return null;
    },
    get_list_bimestres() {
      const payload = {};
      payload.ciclo = this.$store.state.settings_obj.ciclo

      if (this.course_id) {
        payload.student = this.$store.state.settings_obj.student
      }
      const self = this;
      this.sendRequest_with_url(this.url_bimestre, payload).then((data) => {      
        self.list_bimester = data.data;
      }).catch((error) => {
        this.handlerError(error)
      });
      return null;
    },
    update_task () {
      let payload = {}
      payload.files = this.current_task.files
      payload.id = this.current_task.id
      payload.set_documents = true
      this.retrieveRequest(payload).then((data) => {
        this.$swal.fire('Actualizado', 'Actualización exitosa.', 'success');
        // this.current_task = data.data;
      }).catch((error) => {
        this.handlerError(error)
      });
    },
    delete_image: function(item, index){
      let payload  = {}
      payload.id = item.id
      this.$swal.fire({
        title: 'Seguro?',
        text: 'El archivo se removerá!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí, remover!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          this.$swal.fire("Enviado!", "El archivo esta siendo borrado.", "info");
          this.deleteRequest(payload).then((data)=> {
            this.current_task.files.splice(item.id, 1)
            item.active = true
            this.$set(this.current_task.files_obj, index, item)
            this.$swal.fire("Borrado!", "El  registro ha sido removido.", "success");
          }).catch((error) => {
            this.handlerError(error)
          })
        } else {
          this.$swal.fire(
            'Cancelado',
            'El archivo esta seguro.',
            'error'
          )
        }
      });
    },
    async putRequest(payload) {
      const data = await this.$http.put(this.url+`${payload.id}/`, payload);
      return data;
    },
    async retrieveRequest(payload) {
      return await this.$http.get(this.url+`${payload.id}/`,{params:  payload});
    },
    async sendRequest(payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(this.url,{params:  payload});
      return result;
    },
    async sendRequest_with_url(url, payload) {
      // const result = await this.$http.get(this.url, payload);
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async deleteRequest(payload) {
      const result = await this.$http.delete(this.url_file+`${payload.id}/`);
      return result;
    },
  },
  filters: {
    status_to_text(value) {
      if (value) return 'Sí';
      return 'No';
    },
    to_date(value) {
      if (!value ) return '-----'      
      return moment(value, 'YYYY-MM-DD', 'es').format('ll')
    },
    to_date_time(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').format('lll')
    },
    to_time(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD HH:mm:ss', 'es').format('LT')
    },
  },
};

</script>
<style lang="css" scoped>
  .msg-received .inner-box:after {
    border-color: transparent #00c292 transparent transparent;  
  }
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;            
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
</style>
